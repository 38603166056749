import * as React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/layout";
import gosia from "../images/gosia160x200.jpg";
import bartek from "../images/bartek133x200.jpg";
import anna from "../images/anna160x200.jpg";
import style from "../styles/kim-jestesmy.module.scss";

const KimJestesmyPage = () => {
  return (
    <Layout>
      <Container className="p-5">
        <h1 class="text-center">Kim jesteśmy - właściciele</h1>
      </Container>
      <Container className={`p-3 lh-lg text-justify ${style.displayFlowRoot}`}>
        <img src={gosia} className={style.photoLeft} width="160" height="200" alt="Gosia" />
        <span className="display-6">Gosia</span> – absolwentka Finansów i Rachunkowości na UEK w Krakowie, kilka
        lat spędziła w audycie – co pozwoliło zdobyć doświadczenie w
        zarządzaniu projektami. W spółce – odpowiedzialna za
        operacyjnie zarządzanie i nadzór nad prowadzonymi projektami. Nade
        wszystko ceni etyczne postępowanie i uczciwość w relacjach
        międzyludzkich. Po godzinach pracy ceni spokojny wypoczynek i
        spotkania z rodziną i przyjaciółmi.
      </Container>
      <Container className={`p-3 lh-lg text-justify ${style.displayFlowRoot}`}>
        <img src={bartek} className={style.photoRight} width="133" height="200" alt="Bartek" />
        <span class="display-6">Bartek</span> – absolwent Gospodarki Przestrzennej na UEK w Krakowie oraz
        Geodezji i Kartografii na UR w Krakowie. Przez ostatnie kilka lat
        pracujący w Finansach, gdzie zbudował doświadczenie w swobodnym
        poruszaniu się w świecie kapitału, oraz zarządzaniu dużymi projektami.
        W Spółce odpowiedzialny za współpracę z Funduszami Inwestycyjnymi oraz
        nadzór nad bieżącymi kwestiami finansowymi. W wolnej chwili pasjonat
        sportu, włoskiej kuchni i wycieczek po Polsce.
      </Container>
      <Container className={`p-3 lh-lg text-justify ${style.displayFlowRoot}`}>
        <img src={anna} className={style.photoLeft} width="160" height="200" alt="Anna" />
        <span class="display-6">Anna</span> – absolwentka Architektury i Urbanistyki na Politechnice
        Krakowskiej z doświadczeniem przy sporządzaniu projektów budowlanych i
        koordynowaniu projektów wielobranżowych. W spółce odpowiedzialna za
        weryfikowanie gruntów pod inwestycje fotowoltaiczne, koordynowanie
        projektów budowlanych oraz prowadzenie spraw formalno-prawnych
        związanych z realizacją inwestycji.  Miłośniczka gór, dzikiej natury i
        herbaty.
      </Container>
      <Container className={`p-3 lh-lg text-justify ${style.displayFlowRoot}`}>
        <span class="display-6">Marek</span> – Od dłuższego czasu zaangażowany w zarządzanie i
        administrowanie Nieruchomościami, po studiach Wyceny i Gospodarki
        Nieruchomościami na Akademii WSB w Dąbrowie Górniczej. Wcześniej
        studia magisterskie i doktoranckie na Akademii Wychowania Fizycznego w
        Katowicach w zakresie kultury fizycznej, Zarządzania i Marketingu w
        Sporcie kwalifikowanym.
        <br/><br/>
        W spółce odpowiedzialny za weryfikowanie gruntów pod inwestycje
        fotowoltaiczne, koordynowanie projektów budowlanych oraz prowadzenie
        spraw formalno-prawnych związanych z realizacją inwestycji. Z
        zamiłowania do sportu, kiedyś piłkarz teraz triathlonista a zimą
        instruktor narciarstwa. Codzienną rutynę doprawia życiem rodzinnym,
        dobrą kawą i jedzeniem.
      </Container>
    </Layout>
  )
}

export default KimJestesmyPage
